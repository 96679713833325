import * as React from 'react'
import clsx from 'clsx'
import { hasValue } from '@digital-magic/ts-common-utils'
import { TitledContainerProps } from '@controls/types'
import styles from './PageSection.module.css'

type MaxWidth = 'sm' | 'md' | 'lg' | 'xl' | false

export type PageSection = TitledContainerProps &
  Readonly<{
    id?: string
    subtitle?: React.ReactNode
    disableRightGap?: boolean
    disableLeftGap?: boolean
    maxWidth?: MaxWidth
  }>

export const PageSection: React.FC<PageSection> = ({
  id,
  title,
  subtitle,
  disableLeftGap,
  disableRightGap,
  maxWidth = 'lg',
  className,
  children
}) => (
  <div
    id={id}
    className={clsx(styles.pageSection, className, {
      [styles.disableLeftGap]: disableLeftGap === true,
      [styles.disableRightGap]: disableRightGap === true,
      [styles.maxWidthSm]: maxWidth === 'sm',
      [styles.maxWidthMd]: maxWidth === 'md',
      [styles.maxWidthLg]: maxWidth === 'lg',
      [styles.maxWidthXl]: maxWidth === 'xl'
    })}
  >
    {hasValue(title) && <h2 className={clsx(styles.title, styles.ignoreExceptions)}>{title}</h2>}
    {hasValue(subtitle) && <h3 className={clsx(styles.subtitle, styles.ignoreExceptions)}>{subtitle}</h3>}
    {children}
  </div>
)
